import React from 'react'
import { size } from 'lodash'
import { ProjectBaseFragment } from 'Typings/generated-gatsby-types'
import AltPageLayout, {
  AltPageLayoutNavs,
  isFirstSectionHasLabel,
} from 'Components/layout/AltPageLayout'
import DynamicSections, {
  DynamicSectionsDataType,
  getDynamicSectionThemes,
} from 'Components/sections/DynamicSections'
import { HeaderThemeOnScrollChanger } from 'Components/layout/Header'
import ProjectsSlider from 'Components/ProjectsSlider/ProjectsSlider'
import { ApiSeo } from 'Typings/api'

export type AltPageMainProps = {
  seo: ApiSeo
  path: string
  heading: string
  sub_heading?: string
  sections: DynamicSectionsDataType[]
  projects?: Array<{ project: ProjectBaseFragment }>
  navs?: AltPageLayoutNavs
}

const AltPageMain = (props: AltPageMainProps) => {
  const { seo, path, heading, sub_heading, navs, sections, projects } = props

  return (
    <AltPageLayout
      seo={seo}
      path={path}
      title={heading}
      subTitle={sub_heading}
      navs={navs}
      firstSectionHasLabel={isFirstSectionHasLabel(sections)}
    >
      <HeaderThemeOnScrollChanger
        themes={[
          ...getDynamicSectionThemes(sections),
          'dark', // ProjectsSlider
        ].map((i) => (i === 'light' ? '' : i))} // На светлой теме остаётся базовым цветом
      />
      <DynamicSections sections={sections} />
      {projects && size(projects) > 0 && <ProjectsSlider projects={projects} />}
    </AltPageLayout>
  )
}

export default AltPageMain
